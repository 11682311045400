.container {
    display: flex;
    font-family: 'avenir-book';
}

.sidebar {
    width: 25%;
    background-color: #f7f7f7;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    max-width: 600px;
}

.sidebar > p{
    width: fit-content;
    padding: 0;
}

.profile-pic {
    width: 50%;
    border-radius: 50%;
    margin-bottom: 20px;
}

.contact-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.contact-links a {
    color: #333;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.name {
    font-size: 30px;
}

.headline {
    font-size: 20px;
    margin-bottom: 20px;
}

.work-container {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    font-size: 20px;
}

.work-item {
    margin-bottom: 20px;
}

.work-item a {
    font-weight: bold;
    color: #007BFF;
    text-decoration: none;
}

.work-item p {
    margin-top: 5px;
}
